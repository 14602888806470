<template>
    <div>
        <Header />
<!--        <div class="navbar">-->
<!--            <el-link @click="menuIndex = 0" :class="{ active: menuIndex == 0 }" :underline="false">黑龙江省交通基础设施建设计划管理系统-->
<!--            </el-link>-->
            <!-- <el-link @click="menuIndex = 1" :class="{ active: menuIndex == 1 }" :underline="false">2021年度计划
            </el-link>
            <el-link disabled title="规划中">2022年度计划</el-link>
            <el-link disabled title="规划中">2023年度计划</el-link>
            <el-link disabled title="规划中">2024年度计划</el-link>
            <el-link disabled title="规划中">2025年度计划</el-link> -->
<!--        </div>-->
        <div v-if="isAdmin">
            <Statistics></Statistics>
<!--            <Plan145  />-->
<!--            <Plan2021 v-if="menuIndex == 1" />-->
        </div>
        <div v-else>
            <First />
        </div>
    </div>
</template>
<script>
import Statistics from "@/views/Home/Statistics.vue";
import First from "@/views/Home/First.vue";
// import Plan2021 from "@/views/Home/Plan_2021.vue";
import Plan145 from "@/views/Home/Plan_2021.vue";
// import Header from "@/views/Home/Header.vue";

export default {
    name: "Home",
    data() {
        return {
            menuIndex: 0, //菜单序号
            isAdmin: false,
        };
    },
    mounted() {
        this.http.post('/api/Sys_User/getCurrentUserInfo',{}).then(res=>{
            this.isAdmin = res.data.roleName == '超级管理员' ? true : false
        })
    },
    methods: {},
    components: {
        First,Statistics
    },
};
</script>
