<template>
    <div class="Plan">
        <div style="display: flex">
<!--            <div style="margin-top: 106px">-->
<!--                <div class="list" :class="active == index ? 'list1' : ''" @click="changeMenu(index)" v-for="(item,index) in list" :key="index"><div class="name">{{item}}</div></div>-->
<!--            </div>-->
            <div class="PlanRight">
               <div class="totalEchart">
                   <div class="label"><div>汇总</div></div>
                   <div style="display: flex;align-items: center">
                       <div class="pieEcharts" id="pie" ></div>
                      <div style="display: flex;width: 70%"  v-show="showIndex == 0">
                          <div class="totalWrap">
                              <div class="totalTie">公路</div>
                              <div><span class="tieNor">项目数量:</span><span class="tieBig">{{roadXmsl}}</span></div>
                              <div  class="tieNor">
                                  <div style="display: flex">
                                      <div>高速公路:</div>
                                      <div>
                                          总投资: <span style="color: #fff;font-size: 12px">{{allGszj}} </span>亿元<br>
                                          建设规模<span style="color: #fff;font-size: 12px">{{allGs}} </span>公里
                                      </div>
                                  </div>
                                  <div style="display: flex">
                                      <div>普通国道:</div>
                                      <div>
                                          总投资: <span style="color: #fff;font-size: 12px">{{allGdzj}} </span>亿元 <br>
                                          建设规模: <span style="color: #fff;font-size: 12px">{{allGd}} </span>公里
                                      </div>
                                  </div>
                                  <div style="display: flex">
                                      <div>普通省道:</div>
                                      <div>
                                          总投资: <span style="color: #fff;font-size: 12px">{{allSdzj}} </span>亿元 <br>
                                          建设规模: <span style="color: #fff;font-size: 12px">{{allSd.toFixed(2)}}</span>公里
                                      </div>
                                  </div>
                              </div>
                          </div>

                          <div class="smallPieEcharts" id="pie1" ></div>
                          <div class="smallPieEcharts" id="pie2" ></div>
                      </div>
                       <div style="display: flex;width: 70%"  v-show="showIndex == 1">
                           <div class="smallPieEcharts" id="pie3" ></div>
                           <div class="smallPieEcharts" id="pie4" ></div>
                           <div class="totalWrap" >
                               <div class="totalTie">铁路</div>
                               <div><span class="tieNor">项目数量:</span><span class="tieBig">{{railwayXmsl}}</span></div>
                               <div  class="tieNor">
                                   <div style="display: flex">
                                       <div>
                                           总投资: <span style="color: #fff;font-size: 12px">{{railwayZtz}} </span>亿元<br>
                                           建设规模<span style="color: #fff;font-size: 12px">{{railwayJsgm}} </span>公里
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>
                       <div style="display: flex;width: 70%"  v-show="showIndex == 2">
                           <div class="singlePieEcharts" id="pie5" ></div>
                           <div class="totalWrap">
                               <div class="totalTie">水运</div>
                               <div><span class="tieNor">项目数量:</span><span class="tieBig">{{waterXmsl}}</span></div>
                               <div  class="tieNor">
                                   <div style="display: flex">
                                       <div>
                                           总投资: <span style="color: #fff;font-size: 12px">{{waterZtz}} </span>亿元<br>
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>
                       <div style="display: flex;width: 70%"  v-show="showIndex == 3">
                           <div class="singlePieEcharts" id="pie6" ></div>
                           <div class="totalWrap">
                               <div class="totalTie">民航</div>
                               <div><span class="tieNor">项目数量:</span><span class="tieBig">{{airportXmsl}}</span></div>
                               <div  class="tieNor">
                                   <div style="display: flex">
                                       <div>
                                           总投资: <span style="color: #fff;font-size: 12px">{{airportZtz}} </span>亿元<br>
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>
                       <div style="display: flex;width: 70%"  v-show="showIndex == 4">
                           <div class="singlePieEcharts" id="pie7" ></div>
                           <div class="totalWrap">
                               <div class="totalTie">运输</div>
                               <div><span class="tieNor">项目数量:</span><span class="tieBig">{{hingeXmsl}}</span></div>
                               <div  class="tieNor">
                                   <div style="display: flex">
                                       <div>
                                           总投资: <span style="color: #fff;font-size: 12px">{{hingeZtz}} </span>亿元<br>
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>
<!--                 <div style="display: flex">-->
<!--                     <div class="pieEcharts" id="pie" ></div>-->
<!--                     <div class="pieEcharts" id="pie1" ></div>-->
<!--                     <div class="pieEcharts" id="pie2" ></div>-->
<!--                     <div v-if="showIndex == 1" class="pieEcharts" id="pie3" ></div>-->
<!--                     <div v-if="showIndex == 0" style="display: flex">-->

<!--                    </div>-->
<!--                    <div v-if="showIndex == 1" class="totalWrap" >-->
<!--                         <div class="totalTie">铁路</div>-->
<!--                         <div><span class="tieNor">项目数量:</span><span class="tieBig">{{railwayXmsl}}</span></div>-->
<!--                         <div  class="tieNor">-->
<!--                             <div style="display: flex">-->
<!--                                 <div>-->
<!--                                     总投资: <span style="color: #fff;font-size: 12px">{{railwayZtz}} </span>亿元<br>-->
<!--                                     建设规模<span style="color: #fff;font-size: 12px">{{railwayJsgm}} </span>公里-->
<!--                                 </div>-->
<!--                             </div>-->
<!--                         </div>-->
<!--                     </div>-->

<!--&lt;!&ndash;                     <div class="totalWrap">&ndash;&gt;-->
<!--&lt;!&ndash;                         <div class="totalTie">民航</div>&ndash;&gt;-->
<!--&lt;!&ndash;                         <div><span class="tieNor">项目数量:</span><span class="tieBig">{{railwayXmsl}}</span></div>&ndash;&gt;-->
<!--&lt;!&ndash;                         <div  class="tieNor">&ndash;&gt;-->
<!--&lt;!&ndash;                             <div style="display: flex">&ndash;&gt;-->
<!--&lt;!&ndash;                                 <div>&ndash;&gt;-->
<!--&lt;!&ndash;                                     总投资: <span style="color: #fff;font-size: 12px">{{railwayZtz}} </span>亿元<br>&ndash;&gt;-->
<!--&lt;!&ndash;                                     建设规模<span style="color: #fff;font-size: 12px">{{railwayJsgm}} </span>公里&ndash;&gt;-->
<!--&lt;!&ndash;                                 </div>&ndash;&gt;-->
<!--&lt;!&ndash;                             </div>&ndash;&gt;-->
<!--&lt;!&ndash;                         </div>&ndash;&gt;-->
<!--&lt;!&ndash;                     </div>&ndash;&gt;-->
<!--&lt;!&ndash;                     <div class="totalWrap">&ndash;&gt;-->
<!--&lt;!&ndash;                         <div class="totalTie">运输</div>&ndash;&gt;-->
<!--&lt;!&ndash;                         <div><span class="tieNor">项目数量:</span><span class="tieBig">{{railwayXmsl}}</span></div>&ndash;&gt;-->
<!--&lt;!&ndash;                         <div  class="tieNor">&ndash;&gt;-->
<!--&lt;!&ndash;                             <div style="display: flex">&ndash;&gt;-->
<!--&lt;!&ndash;                                 <div>&ndash;&gt;-->
<!--&lt;!&ndash;                                     总投资: <span style="color: #fff;font-size: 12px">{{railwayZtz}} </span>亿元<br>&ndash;&gt;-->
<!--&lt;!&ndash;                                     建设规模<span style="color: #fff;font-size: 12px">{{railwayJsgm}} </span>公里&ndash;&gt;-->
<!--&lt;!&ndash;                                 </div>&ndash;&gt;-->
<!--&lt;!&ndash;                             </div>&ndash;&gt;-->
<!--&lt;!&ndash;                         </div>&ndash;&gt;-->
<!--&lt;!&ndash;                     </div>&ndash;&gt;-->




<!--&lt;!&ndash;                     <div class="pieEcharts" id="pie1" ></div>&ndash;&gt;-->
<!--                 </div>-->
               </div>
<!--                <div class="echart">-->
<!--                    <div class="label"><div>危桥</div></div>-->
<!--                    <div>-->
<!--                        <div class="echarts" id="echarts2" ></div>-->
<!--                    </div>-->
<!--                </div>-->
               <div class="el-row--flex" style="justify-content: space-around">
                   <div class="echart">
                       <div class="label"><div>公路</div></div>
                       <div>
                           <div class="echarts" id="echarts1" ></div>
                       </div>
                   </div>
                   <div class="echart">
                       <div class="label"><div>铁路</div></div>
                       <div>
                           <div class="echarts" id="echarts3" ></div>
                       </div>
                   </div>
               </div>
                <div class="el-row--flex" style="justify-content: space-around">
                    <div class="echart">
                        <div class="label"><div>水运</div></div>
                        <div>
                            <div class="echarts" id="echarts5" ></div>
                        </div>
                    </div>
                    <div class="echart">
                        <div class="label"><div>民航</div></div>
                        <div>
                            <div class="echarts" id="echarts4"></div>
                        </div>
                    </div>
                    <div class="echart">
                        <div class="label"><div>运输</div></div>
                        <div>
                            <div class="echarts" id="echarts6" ></div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Plan",
        data(){
            return{
                showIndex:0,
                list:['十四五专项统计','双碳统计'],
                active:0,
                roadYear:[],
                bridgeYear:[],
                roadXmsl:0,
                roadJsgm:0,
                roadZtz:0,
                railwayXmsl:0,
                railwayJsgm:0,
                railwayZtz:0,
                airportZtz:0,
                airportXmsl:0,
                waterZtz:0,
                waterXmsl:0,
                hingeZtz:0,
                hingeXmsl:0,
                allGs:0,
                allGd:0,
                allSd:0,
                alltz:0,
                allGszj:0,
                allGdzj:0,
                allSdzj:0,
                tlpie1:[],
                tlpie2:[],
                sypie1:[],
                mhpie:[],
                hingpie:[],
            }
        },
        mounted(){
            var that = this
            this.getPageTotal()
            this.allGs = 0
            this.allGd = 0
            this.allSd = 0
            this.http.post('/api/Plan_road/PlanStatisticsSummary',{}).then(res=>{
                console.log(res)
                var year = []
                var bridgeYear = []
                var tlYear = []
                var mhYear = []
                var syYear = []
                var ysYear = []
                res['公路'].map((item,index)=>{
                    if (item.ND != '' && item.ND != null){
                        year.push(item.ND)
                    }
                })
                const map = new Map()
                this.roadYear= year.filter(key => !map.has(key) && map.set(key, 1))
                console.log(that.bridgeYear)
                this.gs =new Array(this.roadYear.length).fill(0);
                this.gszj =new Array(this.roadYear.length).fill(0);
                this.gd =new Array(this.roadYear.length).fill(0);
                this.gdzj =new Array(this.roadYear.length).fill(0);
                this.sd = new Array(this.roadYear.length).fill(0);
                this.sdzj = new Array(this.roadYear.length).fill(0);
                this.gltz = new Array(this.roadYear.length).fill(0);
                this.roadYear.map((item,index)=>{
                    var tz = 0
                    res['公路'].map((subItem,subIndex)=>{
                        if (subItem.ND != '' && subItem.ND != null){
                            if (subItem.ND == item){
                                tz += subItem.ZTZ
                                this.gltz.splice(index,1,parseFloat(tz.toFixed(2)))
                            }
                            switch (subItem.GLLX) {
                                case '高速公路':
                                    if(subItem.ND == item){
                                        console.log(subItem)
                                        this.gs.splice(index,1,subItem.JSGM)
                                        this.gszj.splice(index,1,subItem.ZTZ)
                                    }
                                    break;
                                case '普通国道':
                                    if(subItem.ND == item){
                                        this.gd.splice(index,1,subItem.JSGM)
                                        this.gdzj.splice(index,1,subItem.ZTZ)
                                    }
                                    break;
                                case '普通省道':
                                    if(subItem.ND == item){
                                        this.sd.splice(index,1,subItem.JSGM)
                                        this.sdzj.splice(index,1,subItem.ZTZ)
                                    }
                                    break;
                            }
                        }
                    })
                })
                this.gs.map((item,index)=>{
                    this.allGs += item
                })
                this.gszj.map((item,index)=>{
                    console.log(item)
                    this.allGszj += item
                })
                this.gd.map((item,index)=>{
                    this.allGd += item
                })
                this.gdzj.map((item,index)=>{
                    this.allGdzj += item
                })
                this.sd.map((item,index)=>{
                    this.allSd += item
                })
                this.sdzj.map((item,index)=>{
                    this.allSdzj += item
                })
                this.gltz.map((item,index)=>{
                    this.alltz += item
                })
                console.log(this.gszj, this.allGszj,' this.allGszj')

                this.gsPieData = [{name:'高速',value:this.allGs.toFixed(2)},{name:'国道',value:this.allGd.toFixed(2)},{name:'省道',value:this.allSd.toFixed(2)}]
                this.gsPie1Data = [{name:'高速',value:this.allGszj.toFixed(2)},{name:'国道',value:parseFloat(this.allGdzj.toFixed(2))},{name:'省道',value:parseFloat(this.allSdzj.toFixed(2))}]
                // this.pie1Data = [{name:'高速公路',value:this.allGszj.toFixed(2)},{name:'普通国道',value:this.allGdzj.toFixed(2)},{name:'普通省道',value:this.allSdzj.toFixed(2)}]
                // res['公路'].map((item,index)=>{
                //     if (item.ND != '' && item.ND != null){
                //         year.push(item.ND)
                //     }
                // })
                // const map = new Map()
                // this.roadYear= year.filter(key => !map.has(key) && map.set(key, 1))
                // console.log(that.bridgeYear)
                // this.gs =new Array(this.roadYear.length).fill(0);
                // this.gd =new Array(this.roadYear.length).fill(0);
                // this.sd = new Array(this.roadYear.length).fill(0);
                // this.gltz = new Array(this.roadYear.length).fill(0);
                //     this.roadYear.map((item,index)=>{
                //         var tz = 0
                //         res['公路'].map((subItem,subIndex)=>{
                //             if (subItem.ND != '' && subItem.ND != null){
                //                 if (subItem.ND == item){
                //                     tz += subItem.ZTZ
                //                     this.gltz.splice(index,1,parseFloat(tz.toFixed(2)))
                //                 }
                //                 switch (subItem.GLLX) {
                //                     case '高速公路':
                //                         if(subItem.ND == item){
                //                             this.gs.splice(index,1,subItem.JSGM)
                //                         }
                //                         break;
                //                     case '普通国道':
                //                         if(subItem.ND == item){
                //                             this.gd.splice(index,1,subItem.JSGM)
                //                         }
                //                         break;
                //                     case '普通省道':
                //                         if(subItem.ND == item){
                //                             this.sd.splice(index,1,subItem.JSGM)
                //                         }
                //                         break;
                //                 }
                //             }
                //         })
                //     })


                res['危桥'].map((item,index)=>{
                    if (item.ND != '' && item.ND != null){
                        bridgeYear.push(item.ND)
                    }
                })
                this.bridgeYear = bridgeYear
                this.wqgm = new Array(this.bridgeYear.length).fill(0);
                this.wqtz = new Array(this.bridgeYear.length).fill(0);
                this.bridgeYear.map((item,index)=>{
                    res['危桥'].map((subItem,subIndex)=>{
                        if (subItem.ND != '' && subItem.ND != null){
                            if (subItem.ND == item){
                                this.wqtz.splice(index,1,parseFloat(subItem.ZTZ.toFixed(2)))
                                this.wqgm.splice(index,1,subItem.JSGM)
                            }
                        }
                    })
                })
                res['铁路'].map((item,index)=>{
                    if (item.XMXZ != '' && item.XMXZ != null){
                        tlYear.push(item.XMXZ)
                    }
                })
                this.tlYear = tlYear
                this.tlgm = new Array(this.tlYear.length).fill(0);
                this.tltz = new Array(this.tlYear.length).fill(0);
                this.tlYear.map((item,index)=>{
                    res['铁路'].map((subItem,subIndex)=>{
                        if (subItem.XMXZ != '' && subItem.XMXZ != null){
                            if (subItem.XMXZ == item){
                                this.tltz.splice(index,1,parseFloat(subItem.ZTZ.toFixed(2)))
                                this.tlgm.splice(index,1,subItem.JSGM)
                            }
                        }
                    })
                })

                this.tlYear.map((item,index)=>{
                    this.tlpie1.push({name:item,value:this.tltz[index]})
                    this.tlpie2.push({name:item,value:this.tlgm[index]})
                })
                console.log( this.tlpie1,this.tlpie2)

                res['民航'].map((item,index)=>{
                    if (item.XMXZ != '' && item.XMXZ != null){
                        mhYear.push(item.XMXZ)
                    }
                })
                this.mhYear = mhYear
                this.mhtz = new Array(this.mhYear.length).fill(0);
                this.mhYear.map((item,index)=>{
                    res['民航'].map((subItem,subIndex)=>{
                        if (subItem.XMXZ != '' && subItem.XMXZ != null){
                            if (subItem.XMXZ == item){
                                this.mhtz.splice(index,1,parseFloat(subItem.ZTZ.toFixed(2)))
                            }
                        }
                    })
                })
                this.mhYear.map((item,index)=>{
                    this.mhpie.push({name:item,value:this.mhtz[index]})
                })



                res['水运'].map((item,index)=>{
                    if (item.XMXZ != '' && item.XMXZ != null){
                        syYear.push(item.XMXZ)
                    }
                })
                this.syYear = syYear
                this.sytz = new Array(this.syYear.length).fill(0);
                this.syYear.map((item,index)=>{
                    res['水运'].map((subItem,subIndex)=>{
                        if (subItem.XMXZ != '' && subItem.XMXZ != null){
                            if (subItem.XMXZ == item){
                                this.sytz.splice(index,1,parseFloat(subItem.ZTZ.toFixed(2)))
                            }
                        }
                    })
                })
                this.syYear.map((item,index)=>{
                    this.sypie1.push({name:item,value:this.sytz[index]})
                })



                res['运输'].map((item,index)=>{
                    if (item.XMXZ != '' && item.XMXZ != null){
                        ysYear.push(item.XMXZ)
                    }
                })
                this.ysYear = ysYear
                this.ystz = new Array(this.ysYear.length).fill(0);
                this.ysYear.map((item,index)=>{
                    res['运输'].map((subItem,subIndex)=>{
                        if (subItem.XMXZ != '' && subItem.XMXZ != null){
                            if (subItem.XMXZ == item){
                                this.ystz.splice(index,1,parseFloat(subItem.ZTZ.toFixed(2)))
                            }
                        }
                    })
                })
                this.ysYear.map((item,index)=>{
                    this.hingpie.push({name:item,value:this.ystz[index]})
                })
                console.log(syYear,this.ystz)

                this.getEcharts1()
                // this.getEcharts2()
                this.getEcharts3()
                this.getEcharts4()
                this.getEcharts5()
                this.getEcharts6()
            })
            window.addEventListener("resize", () => {
                this.myChart1.resize();
                this.myChart2.resize();
                this.myChart3.resize();
                this.myChart4.resize();
                this.myChart5.resize();
                this.myChart6.resize();
            });
        },
        methods:{
            //获取各类合计数据
            getPageTotal() {
                //公路
                this.http.post("/api/Plan_road/GetPageTotal",{})
                    .then((res) => {
                        this.roadXmsl = res.xmsl;
                        this.roadJsgm = res.jsgm;
                        this.roadZtz = res.ztz;
                    });
//铁路
                this.http.post("/api/Plan_railway/GetPageTotal", {})
                    .then((res) => {
                        this.railwayJsgm = res.jsgm;
                        this.railwayZtz  = res.sswtz;
                        this.railwayXmsl = res.xmsl;
                    });
//民航
                this.http.post("/api/Plan_airport/GetPageTotal", {})
                    .then((res) => {
                        this.airportZtz = res.sswtz;
                        this.airportXmsl = res.xmsl;
                    });
                //水运
                this.http.post("/api/Plan_water/GetPageTotal", {})
                    .then((res) => {
                        this.waterZtz = res.sswtz;
                        this.waterXmsl = res.xmsl;
                    });
                //枢纽场站Plan_hinge
                this.http.post("/api/Plan_hinge/GetPageTotal", {})
                    .then((res) => {
                        this.hingeZtz = res.sswtz;
                        this.hingeXmsl = res.xmsl;
                    });
                setTimeout(()=>{
                    this.getPie()
                    this.getPie1()
                    this.getPie2()
                },1000)

            },
            changeMenu(index){
                this.active = index
            },
            //汇总 投资
            getPie() {
                var that = this
                this.myChart1 = this.$echarts.init(
                    document.getElementById("pie")
                );
                var pieoption = {
                    tooltip: {
                        trigger: 'item',
                        formatter: '{b} : {d}%',
                    },
                    icon: 'squire', // 图例色块是方还是圆
                    title: {
                        // backgroundColor:'#001F67',
                        // borderRadius:100,
                        // padding:40,
                        left:'middle',
                        top:'30%',
                        itemGap:20,
                        text: (that.roadZtz+that.railwayZtz+that.waterZtz+that.hingeZtz+that.airportZtz).toFixed(2),
                        subtext: '总投资(亿元)',
                        textStyle: {
                            fontSize: 20,
                            color: "#ffffff",
                            fontWeight: 500,
                        },
                        subtextStyle: {
                            fontSize: 12,
                            color: "#ffffff",
                            fontWeight: 400,
                            lineHeight:0,
                        },
                        textAlign: "center", //图例文字居中显示
                        x: "44.5%",   //距离左边的距离
                        y: "17%"    //距离上边的距离
                    },
                    color:['#0070FB','#1ED8F4','#FF5C5C','#FDB628','#9FDAFC'],
                    legend: {
                        textStyle:{
                            color:'#fff',
                        },
                        bottom:30,
                        itemGap: 23,
                    },
                    series: [
                        {
                            name: '',
                            type: 'pie',
                            radius: ['45%', '65%'],
                            center: ['50%', '40%'],
                            label: {
                                show: true,
                                color:'#ffffff',
                                labelLine:{
                                    lineStyle:{
                                        color:['#EB6F49','#1ED8F4','#FF5C5C','#0070FB'],
                                    }
                                },
                                formatter: "{value|{c}}亿元",
                                rich:{
                                    value:{
                                        fontSize:14,
                                        color:"#fff"
                                    }
                                }
                            },
                            emphasis: {
                                label: {
                                    show: true,
                                    fontSize: '16',
                                    fontWeight: 'bold',
                                }
                            },
                            labelLine: {
                                show: true
                            },
                            data: [{name:'公路',value:this.roadZtz.toFixed(2)},{name:'铁路',value:this.railwayZtz.toFixed(2)},{name:'水运',value:this.waterZtz.toFixed(2)},
                                {name:'机场',value:this.airportZtz.toFixed(2)},{name:'枢纽场站',value:this.hingeZtz.toFixed(2)}]
                        }
                    ]
                };
                this.myChart1.setOption(pieoption);
                this.myChart1.on("click", this.pieClick);
            },
            pieClick(params){
                var that = this
                console.log(params)
                if(params.name == '公路'){
                    this.showIndex = 0
                    setTimeout(()=>{
                        this.getPie1()
                        this.getPie2()
                    },200)
                }
                if(params.name == '铁路'){
                    this.showIndex = 1
                    setTimeout(()=>{
                        this.getPie3()
                        this.getPie4()
                    },200)

                }
                if(params.name == '水运'){
                    this.showIndex = 2
                    setTimeout(()=>{
                        this.getPie5()
                    },200)

                }
                if(params.name == '机场'){
                    this.showIndex = 3
                    setTimeout(()=>{
                        this.getPie6()
                    },200)

                }
                if(params.name == '枢纽场站'){
                    this.showIndex = 4
                    setTimeout(()=>{
                        this.getPie7()
                    },200)

                }
            },
            //汇总 里程
            getPie1() {
                var that = this
                this.myChart1 = this.$echarts.init(
                    document.getElementById("pie1")
                );
                var pieoption = {
                    tooltip: {
                        trigger: 'item',
                        formatter: '{b} : {d}%',
                    },
                    icon: 'squire', // 图例色块是方还是圆
                    title: {
                        // backgroundColor:'#001F67',
                        // borderRadius:100,
                        // padding:40,
                        left:'middle',
                        top:'30%',
                        itemGap:20,
                        text: (that.roadZtz).toFixed(2),
                        subtext: '总投资(亿元)',
                        textStyle: {
                            fontSize: 20,
                            color: "#ffffff",
                            fontWeight: 500,
                        },
                        subtextStyle: {
                            fontSize: 12,
                            color: "#ffffff",
                            fontWeight: 400,
                            lineHeight:0,
                        },
                        textAlign: "center", //图例文字居中显示
                        x: "44.5%",   //距离左边的距离
                        y: "17%"    //距离上边的距离
                    },
                    color:['#0070FB','#1ED8F4','#FF5C5C'],
                    legend: {
                        textStyle:{
                            color:'#fff',
                        },
                        bottom:30,
                        itemGap: 23,
                    },
                    series: [
                        {
                            name: '',
                            type: 'pie',
                            radius: ['45%', '65%'],
                            center: ['50%', '40%'],
                            label: {
                                show: true,
                                color:'#ffffff',
                                labelLine:{
                                    lineStyle:{
                                        color:['#0070FB','#1ED8F4','#FF5C5C'],
                                    }
                                },
                                formatter: "{value|{c}}亿元",
                                rich:{
                                    value:{
                                        fontSize:14,
                                        color:"#fff"
                                    }
                                }
                            },
                            emphasis: {
                                label: {
                                    show: true,
                                    fontSize: '16',
                                    fontWeight: 'bold',
                                }
                            },
                            labelLine: {
                                show: true
                            },
                            data: this.gsPie1Data
                        }
                    ]
                };
                this.myChart1.setOption(pieoption);
                this.myChart1.on("click", this.eConsole);
            },
            getPie2() {
                var that = this
                this.myChart1 = this.$echarts.init(
                    document.getElementById("pie2")
                );
                var pieoption = {
                    tooltip: {
                        trigger: 'item',
                        formatter: '{b} : {d}%',
                    },
                    icon: 'squire', // 图例色块是方还是圆
                    title: {
                        // backgroundColor:'#001F67',
                        // borderRadius:100,
                        // padding:40,
                        left:'middle',
                        top:'30%',
                        itemGap:20,
                        text: (that.roadJsgm).toFixed(2),
                        subtext: '建设规模(公里)',
                        textStyle: {
                            fontSize: 20,
                            color: "#ffffff",
                            fontWeight: 500,
                        },
                        subtextStyle: {
                            fontSize: 12,
                            color: "#ffffff",
                            fontWeight: 400,
                            lineHeight:0,
                        },
                        textAlign: "center", //图例文字居中显示
                        x: "44.5%",   //距离左边的距离
                        y: "17%"    //距离上边的距离
                    },
                    color:['#0070FB','#1ED8F4','#FF5C5C'],
                    legend: {
                        textStyle:{
                            color:'#fff',
                        },
                        bottom:30,
                        itemGap: 23,
                    },
                    series: [
                        {
                            name: '',
                            type: 'pie',
                            radius: ['45%', '65%'],
                            center: ['50%', '40%'],
                            label: {
                                show: true,
                                color:'#ffffff',
                                labelLine:{
                                    lineStyle:{
                                        color:['#0070FB','#1ED8F4','#FF5C5C'],
                                    }
                                },
                                formatter: "{value|{c}}公里",
                                rich:{
                                    value:{
                                        fontSize:14,
                                        color:"#fff"
                                    }
                                }
                            },
                            emphasis: {
                                label: {
                                    show: true,
                                    fontSize: '16',
                                    fontWeight: 'bold',
                                }
                            },
                            labelLine: {
                                show: true
                            },
                            data: this.gsPieData
                        }
                    ]
                };
                this.myChart1.setOption(pieoption);
                this.myChart1.on("click", this.eConsole);
            },
            getPie3() {
                var that = this
                this.pieChart3 = this.$echarts.init(
                    document.getElementById("pie3")
                );
                var pieoption = {
                    tooltip: {
                        trigger: 'item',
                        formatter: '{b} : {d}%',
                    },
                    icon: 'squire', // 图例色块是方还是圆
                    title: {
                        // backgroundColor:'#001F67',
                        // borderRadius:100,
                        // padding:40,
                        left:'middle',
                        top:'30%',
                        itemGap:20,
                        text: (that.railwayZtz).toFixed(2),
                        subtext: '总投资(亿元)',
                        textStyle: {
                            fontSize: 20,
                            color: "#ffffff",
                            fontWeight: 500,
                        },
                        subtextStyle: {
                            fontSize: 12,
                            color: "#ffffff",
                            fontWeight: 400,
                            lineHeight:0,
                        },
                        textAlign: "center", //图例文字居中显示
                        x: "44.5%",   //距离左边的距离
                        y: "17%"    //距离上边的距离
                    },
                    color:['#0070FB','#1ED8F4','#FF5C5C'],
                    legend: {
                        textStyle:{
                            color:'#fff',
                        },
                        bottom:30,
                        itemGap: 23,
                    },
                    series: [
                        {
                            name: '',
                            type: 'pie',
                            radius: ['45%', '65%'],
                            center: ['50%', '40%'],
                            label: {
                                show: true,
                                color:'#ffffff',
                                labelLine:{
                                    lineStyle:{
                                        color:['#0070FB','#1ED8F4','#FF5C5C'],
                                    }
                                },
                                formatter: "{value|{c}}亿元",
                                rich:{
                                    value:{
                                        fontSize:14,
                                        color:"#fff"
                                    }
                                }
                            },
                            emphasis: {
                                label: {
                                    show: true,
                                    fontSize: '16',
                                    fontWeight: 'bold',
                                }
                            },
                            labelLine: {

                                show: true
                            },
                            data: this.tlpie1
                        }
                    ]
                };
                this.pieChart3.setOption(pieoption);
                this.pieChart3.on("click", this.eConsole);
            },
            getPie4() {
                var that = this
                this.pieChart4 = this.$echarts.init(
                    document.getElementById("pie4")
                );
                var pieoption = {
                    tooltip: {
                        trigger: 'item',
                        formatter: '{b} : {d}%',
                    },
                    icon: 'squire', // 图例色块是方还是圆
                    title: {
                        // backgroundColor:'#001F67',
                        // borderRadius:100,
                        // padding:40,
                        left:'middle',
                        top:'30%',
                        itemGap:20,
                        text: (that.railwayJsgm).toFixed(2),
                        subtext: '建设规模(公里)',
                        textStyle: {
                            fontSize: 20,
                            color: "#ffffff",
                            fontWeight: 500,
                        },
                        subtextStyle: {
                            fontSize: 12,
                            color: "#ffffff",
                            fontWeight: 400,
                            lineHeight:0,
                        },
                        textAlign: "center", //图例文字居中显示
                        x: "44.5%",   //距离左边的距离
                        y: "17%"    //距离上边的距离
                    },
                    color:['#0070FB','#1ED8F4','#FF5C5C'],
                    legend: {
                        textStyle:{
                            color:'#fff',
                        },
                        bottom:30,
                        itemGap: 23,
                    },
                    series: [
                        {
                            name: '',
                            type: 'pie',
                            radius: ['45%', '65%'],
                            center: ['50%', '40%'],
                            label: {
                                show: true,
                                color:'#ffffff',
                                labelLine:{
                                    lineStyle:{
                                        color:['#0070FB','#1ED8F4','#FF5C5C'],
                                    }
                                },
                                formatter: "{value|{c}}公里",
                                rich:{
                                    value:{
                                        fontSize:14,
                                        color:"#fff"
                                    }
                                }
                            },
                            emphasis: {
                                label: {
                                    show: true,
                                    fontSize: '16',
                                    fontWeight: 'bold',
                                }
                            },
                            labelLine: {
                                show: true
                            },
                            data: this.tlpie2
                        }
                    ]
                };
                this.pieChart4.setOption(pieoption);
            },
            getPie5() {
                var that = this
                this.pieChart5 = this.$echarts.init(
                    document.getElementById("pie5")
                );
                var pieoption = {
                    tooltip: {
                        trigger: 'item',
                        formatter: '{b} : {d}%',
                    },
                    icon: 'squire', // 图例色块是方还是圆
                    title: {
                        // backgroundColor:'#001F67',
                        // borderRadius:100,
                        // padding:40,
                        left:'middle',
                        top:'30%',
                        itemGap:20,
                        text: (that.waterZtz).toFixed(2),
                        subtext: '总投资(亿元)',
                        textStyle: {
                            fontSize: 20,
                            color: "#ffffff",
                            fontWeight: 500,
                        },
                        subtextStyle: {
                            fontSize: 12,
                            color: "#ffffff",
                            fontWeight: 400,
                            lineHeight:0,
                        },
                        textAlign: "center", //图例文字居中显示
                        x: "44.5%",   //距离左边的距离
                        y: "17%"    //距离上边的距离
                    },
                    color:['#0070FB','#1ED8F4','#FF5C5C','#FDB628','#9FDAFC'],
                    legend: {
                        textStyle:{
                            color:'#fff',
                        },
                        bottom:30,
                        itemGap: 23,
                    },
                    series: [
                        {
                            name: '',
                            type: 'pie',
                            radius: ['45%', '65%'],
                            center: ['50%', '40%'],
                            label: {
                                show: true,
                                color:'#ffffff',
                                labelLine:{
                                    lineStyle:{
                                        color:['#0070FB','#1ED8F4','#FF5C5C','#FDB628','#9FDAFC'],
                                    }
                                },
                                formatter: "{value|{c}}亿元",
                                rich:{
                                    value:{
                                        fontSize:14,
                                        color:"#fff"
                                    }
                                }
                            },
                            emphasis: {
                                label: {
                                    show: true,
                                    fontSize: '16',
                                    fontWeight: 'bold',
                                }
                            },
                            labelLine: {
                                show: true
                            },
                            data: this.sypie1
                        }
                    ]
                };
                this.pieChart5.setOption(pieoption);
            },
            getPie6() {
                var that = this
                this.pieChart6 = this.$echarts.init(
                    document.getElementById("pie6")
                );
                var pieoption = {
                    tooltip: {
                        trigger: 'item',
                        formatter: '{b} : {d}%',
                    },
                    icon: 'squire', // 图例色块是方还是圆
                    title: {
                        // backgroundColor:'#001F67',
                        // borderRadius:100,
                        // padding:40,
                        left:'middle',
                        top:'30%',
                        itemGap:20,
                        text: (that.airportZtz).toFixed(2),
                        subtext: '总投资(亿元)',
                        textStyle: {
                            fontSize: 20,
                            color: "#ffffff",
                            fontWeight: 500,
                        },
                        subtextStyle: {
                            fontSize: 12,
                            color: "#ffffff",
                            fontWeight: 400,
                            lineHeight:0,
                        },
                        textAlign: "center", //图例文字居中显示
                        x: "44.5%",   //距离左边的距离
                        y: "17%"    //距离上边的距离
                    },
                    color:['#0070FB','#1ED8F4','#FF5C5C','#FDB628','#9FDAFC'],
                    legend: {
                        textStyle:{
                            color:'#fff',
                        },
                        bottom:30,
                        itemGap: 23,
                    },
                    series: [
                        {
                            name: '',
                            type: 'pie',
                            radius: ['45%', '65%'],
                            center: ['50%', '40%'],
                            label: {
                                show: true,
                                color:'#ffffff',
                                labelLine:{
                                    lineStyle:{
                                        color:['#0070FB','#1ED8F4','#FF5C5C','#FDB628','#9FDAFC'],
                                    }
                                },
                                formatter: "{value|{c}}亿元",
                                rich:{
                                    value:{
                                        fontSize:14,
                                        color:"#fff"
                                    }
                                }
                            },
                            emphasis: {
                                label: {
                                    show: true,
                                    fontSize: '16',
                                    fontWeight: 'bold',
                                }
                            },
                            labelLine: {
                                show: true
                            },
                            data: this.mhpie
                        }
                    ]
                };
                this.pieChart6.setOption(pieoption);
            },
            getPie7() {
                var that = this
                this.pieChart7 = this.$echarts.init(
                    document.getElementById("pie7")
                );
                var pieoption = {
                    tooltip: {
                        trigger: 'item',
                        formatter: '{b} : {d}%',
                    },
                    icon: 'squire', // 图例色块是方还是圆
                    title: {
                        // backgroundColor:'#001F67',
                        // borderRadius:100,
                        // padding:40,
                        left:'middle',
                        top:'30%',
                        itemGap:20,
                        text: (that.hingeZtz).toFixed(2),
                        subtext: '总投资(亿元)',
                        textStyle: {
                            fontSize: 20,
                            color: "#ffffff",
                            fontWeight: 500,
                        },
                        subtextStyle: {
                            fontSize: 12,
                            color: "#ffffff",
                            fontWeight: 400,
                            lineHeight:0,
                        },
                        textAlign: "center", //图例文字居中显示
                        x: "44.5%",   //距离左边的距离
                        y: "17%"    //距离上边的距离
                    },
                    color:['#0070FB','#1ED8F4','#FF5C5C','#FDB628','#9FDAFC'],
                    legend: {
                        textStyle:{
                            color:'#fff',
                        },
                        bottom:30,
                        itemGap: 23,
                    },
                    series: [
                        {
                            name: '',
                            type: 'pie',
                            radius: ['45%', '65%'],
                            center: ['50%', '40%'],
                            label: {
                                show: true,
                                color:'#ffffff',
                                labelLine:{
                                    lineStyle:{
                                        color:['#0070FB','#1ED8F4','#FF5C5C','#FDB628','#9FDAFC'],
                                    }
                                },
                                formatter: "{value|{c}}亿元",
                                rich:{
                                    value:{
                                        fontSize:14,
                                        color:"#fff"
                                    }
                                }
                            },
                            emphasis: {
                                label: {
                                    show: true,
                                    fontSize: '16',
                                    fontWeight: 'bold',
                                }
                            },
                            labelLine: {
                                show: true
                            },
                            data: this.hingpie
                        }
                    ]
                };
                this.pieChart7.setOption(pieoption);
            },
            //公路
            getEcharts1() {
                 this.myChart1 = this.$echarts.init(
                    document.getElementById("echarts1")
                );
                var pieoption = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#fff'
                            }
                        }
                    },
                    toolbox: {
                       show:false
                    },
                    legend: {
                        data: ['高速', '普通国道', '普通省道'],
                        left:'right',
                        textStyle:{//图例文字的样式
                            color:'#9FDAFC',
                            fontSize:12
                        }
                    },
                    // dataZoom: [{
                    //     type: 'slider',
                    //     show: true,
                    //     xAxisIndex: [0],
                    //     start: 0,
                    //     end: 50 //初始化滚动条
                    // }],
                    xAxis: [
                        {
                            type: 'category',
                            data: this.roadYear,
                            axisPointer: {
                                type: 'shadow'
                            },
                            axisLabel:{
                                color:'#9FDAFC',

                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            name: '建设规模（公里）',

                            nameTextStyle:{
                                color:'#9FDAFC',
                            },
                            splitLine:{
                                show: true,
                                lineStyle:{
                                    color:['rgba(96, 118, 173, 0.3)'],
                                    type: 'solid'
                                }
                            },
                            axisLabel: {
                                formatter: '{value}',
                                textStyle:{//图例文字的样式
                                    color:'#9FDAFC',
                                    fontSize:14,
                                }
                            }
                        },
                        {
                            type: 'value',
                            name: '投资金额（亿元）',

                            nameTextStyle:{
                                color:'#9FDAFC',
                            },
                            splitLine:{
                                show: true,
                                lineStyle:{
                                    color:['rgba(96, 118, 173, 0.3)'],
                                    type: 'solid'
                                }
                            },
                            axisLabel: {
                                textStyle:{//图例文字的样式
                                    color:'#9FDAFC',
                                    fontSize:14
                                },
                                formatter: '{value} '
                            }
                        }
                    ],
                    series: [
                        {
                            name: '高速',
                            type: 'bar',
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + '公里';
                                }
                            },
                            label: {
                                show: true,
                                color:'#9FDAFC',
                                fontSize:'12px',
                                position: 'top'
                            },
                            itemStyle:{
                                normal:{
                                    barBorderRadius:[5,5,0,0],
                                    color:'#1ED8F4'
                                }
                            },
                            data: this.gs
                        },
                        {
                            name: '普通国道',
                            type: 'bar',
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + ' 公里';
                                }
                            },
                            label: {
                                show: true,
                                color:'#9FDAFC',
                                fontSize:'12px',
                                position: 'top'
                            },
                            itemStyle:{
                                normal:{
                                    barBorderRadius:[5,5,0,0],
                                    color:'#FF5C5C'
                                }
                            },
                            data:  this.gd
                        },
                        {
                            name: '普通省道',
                            type: 'bar',
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + ' 公里';
                                }
                            },
                            label: {
                                show: true,
                                color:'#9FDAFC',
                                fontSize:'12px',
                                position: 'top'
                            },
                            data:  this.sd,
                            itemStyle:{
                                normal:{
                                    barBorderRadius:[5,5,0,0],
                                    color:'#0070FB'
                                }
                            },
                        },
                        {
                            name: '总投资金额',
                            type: 'line',
                            yAxisIndex: 1,
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + '亿元';
                                }
                            },
                            label: {
                                show: true,
                                color:'#FDB628',
                                fontSize:'12px',
                                position: 'top',
                            },
                            itemStyle:{
                                normal:{
                                    color:'#FDB628'
                                }
                            },
                            data: this.gltz
                        }
                    ]
                };
                this.myChart1.setOption(pieoption);

            },
            //危桥
            getEcharts2() {
                var that = this
                this.myChart2 = this.$echarts.init(
                    document.getElementById("echarts2")
                );
                var pieoption = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#fff'
                            }
                        }
                    },
                    toolbox: {
                        show:false
                    },

                    xAxis: [
                        {
                            type: 'category',
                            data: that.bridgeYear,
                            axisPointer: {
                                type: 'shadow'
                            },
                            axisLabel:{
                                color:'#9FDAFC',

                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            name: '建设规模（延米）',

                            nameTextStyle:{
                                color:'#9FDAFC',
                            },
                            splitLine:{
                                show: true,
                                lineStyle:{
                                    color:['rgba(96, 118, 173, 0.3)'],
                                    type: 'solid'
                                }
                            },
                            axisLabel: {
                                formatter: '{value}',
                                textStyle:{//图例文字的样式
                                    color:'#9FDAFC',
                                    fontSize:14
                                }
                            }
                        },
                        {
                            type: 'value',
                            name: '投资金额（万元）',
                            nameTextStyle:{
                                color:'#9FDAFC',
                            },
                            splitLine:{
                                show: true,
                                lineStyle:{
                                    color:['rgba(96, 118, 173, 0.3)'],
                                    type: 'solid'
                                }
                            },
                            axisLabel: {
                                textStyle:{//图例文字的样式
                                    color:'#9FDAFC',
                                    fontSize:14
                                },
                                formatter: '{value} '
                            }
                        }
                    ],
                    series: [
                        {
                            name: '建设规模',
                            type: 'bar',
                            barWidth : 30,
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + '公里';
                                }
                            },
                            label: {
                                show: true,
                                color:'#9FDAFC',
                                fontSize:'12px',
                                position: 'top'
                            },
                            itemStyle:{
                                normal:{
                                    barBorderRadius:[5,5,0,0],
                                    color: (params) => {
                                        return new that.$echarts.graphic.LinearGradient(0,0,0,1,[
                                            {offset: 0.2, color:'#28D8E8'},
                                            {offset: 1, color: '#0489F0'}
                                        ])
                                    }
                                }
                            },
                            data: that.wqgm
                        },
                        {
                            name: '投资金额',
                            type: 'line',
                            yAxisIndex: 1,
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + '万元';
                                }
                            },
                            label: {
                                show: true,
                                color:'#9FDAFC',
                                fontSize:'12px',
                                position: 'top'
                            },
                            itemStyle:{
                                normal:{
                                    color:'#FDB628'
                                }
                            },
                            data: that.wqtz
                        }
                    ]
                };
                this.myChart2.setOption(pieoption);
            },
            //铁路
            getEcharts3() {
                var that = this
                this.myChart3 = this.$echarts.init(
                    document.getElementById("echarts3")
                );
                var pieoption = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#fff'
                            }
                        }
                    },
                    toolbox: {
                        show:false
                    },
                    legend: {
                        data: this.tlYear,
                        left:'right',
                        textStyle:{//图例文字的样式
                            color:'#9FDAFC',
                            fontSize:14
                        }
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data:  this.tlYear,
                            axisPointer: {
                                type: 'shadow'
                            },
                            axisLabel:{
                                color:'#9FDAFC',

                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            name: '建设规模（公里）',
                            nameTextStyle:{
                                color:'#9FDAFC',
                            },
                            axisLabel: {
                                formatter: '{value}',
                                textStyle:{//图例文字的样式
                                    color:'#9FDAFC',
                                    fontSize:14
                                }
                            },
                            splitLine:{
                                show: true,
                                lineStyle:{
                                    color:['rgba(96, 118, 173, 0.3)'],
                                    type: 'solid'
                                }
                            },

                        },
                        {
                            type: 'value',
                            name: '投资金额（亿元）',
                            nameTextStyle:{
                                color:'#9FDAFC',
                            },
                            splitLine:{
                                show: true,
                                lineStyle:{
                                    color:['rgba(96, 118, 173, 0.3)'],
                                    type: 'solid'
                                }
                            },
                            axisLabel: {
                                textStyle:{//图例文字的样式
                                    color:'#9FDAFC',
                                    fontSize:14
                                },
                                formatter: '{value} '
                            }
                        }
                    ],
                    series: [
                        {
                            name: '建设规模',
                            type: 'bar',
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + '公里';
                                }
                            },
                            label: {
                                show: true,
                                color:'#9FDAFC',
                                fontSize:'12px',
                                position: 'top'
                            },
                            barWidth : 30,
                            itemStyle:{
                                normal:{
                                    barBorderRadius:[5,5,0,0],
                                    color: (params) => {
                                        return new that.$echarts.graphic.LinearGradient(0,0,0,1,[
                                            {offset: 0.2, color:'#28D8E8'},
                                            {offset: 1, color: '#0489F0'}
                                        ])
                                    }
                                }
                            },
                            data: this.tlgm
                        },
                        {
                            name: '投资金额',
                            type: 'line',
                            yAxisIndex: 1,
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + '万元';
                                }
                            },
                            label: {
                                show: true,
                                color:'#FDB628',
                                fontSize:'14px',
                                position: 'top'
                            },
                            itemStyle:{
                                normal:{
                                    color:'#FDB628'
                                }
                            },
                            data: that.tltz
                        }
                    ]
                };
                this.myChart3.setOption(pieoption);
            },
            //民航
            getEcharts4() {
                this.myChart4 = this.$echarts.init(
                    document.getElementById("echarts4")
                );
                var pieoption = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#fff'
                            }
                        }
                    },
                    toolbox: {
                        show:false
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: this.mhYear,
                            axisPointer: {
                                type: 'shadow'
                            },
                            axisLabel:{
                                color:'#9FDAFC',

                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            name: '投资金额（亿元）',
                            nameTextStyle:{
                                color:'#9FDAFC',
                            },
                            splitLine:{
                                show: true,
                                lineStyle:{
                                    color:['rgba(96, 118, 173, 0.3)'],
                                    type: 'solid'
                                }
                            },
                            axisLabel: {
                                formatter: '{value}',
                                textStyle:{//图例文字的样式
                                    color:'#9FDAFC',
                                    fontSize:14
                                }
                            }
                        },
                    ],
                    series: [
                        {
                            name: '投资金额',
                            type: 'bar',
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + '亿元';
                                }
                            },
                            label: {
                                show: true,
                                color:'#9FDAFC',
                                fontSize:'12px',
                                position: 'top'
                            },
                            barWidth : 30,
                            itemStyle:{
                                normal:{
                                    barBorderRadius:[5,5,0,0],
                                    color: (params) => {
                                        return new this.$echarts.graphic.LinearGradient(0,0,0,1,[
                                            {offset: 0.2, color:'#FEE6B6'},
                                            {offset: 1, color: '#FDB628'}
                                        ])
                                    }
                                }
                            },
                            data: this.mhtz
                        }
                    ]
                };
                this.myChart4.setOption(pieoption);
            },
            //水运
            getEcharts5() {
                this.myChart5 = this.$echarts.init(
                    document.getElementById("echarts5")
                );
                var pieoption = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#fff'
                            }
                        }
                    },
                    toolbox: {
                        show:false
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: this.syYear,
                            axisPointer: {
                                type: 'shadow'
                            },
                            axisLabel:{
                                color:'#9FDAFC',
                                fontSize:12,

                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            name: '投资金额（亿元）',
                            nameTextStyle:{
                                color:'#9FDAFC',
                            },
                            splitLine:{
                                show: true,
                                lineStyle:{
                                    color:['rgba(96, 118, 173, 0.3)'],
                                    type: 'solid'
                                }
                            },
                            axisLabel: {
                                formatter: '{value}',
                                textStyle:{//图例文字的样式
                                    color:'#9FDAFC',
                                    fontSize:14
                                }
                            }
                        },
                    ],
                    series: [
                        {
                            name: '投资金额',
                            type: 'bar',
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + '亿元';
                                }
                            },
                            label: {
                                show: true,
                                color:'#9FDAFC',
                                fontSize:'12px',
                                position: 'top'
                            },
                            barWidth : 30,
                            itemStyle:{
                                normal:{
                                    barBorderRadius:[5,5,0,0],
                                    color: (params) => {
                                        return new this.$echarts.graphic.LinearGradient(0,0,0,1,[
                                            {offset: 0.2, color:'#FEE6B6'},
                                            {offset: 1, color: '#FDB628'}
                                        ])
                                    }
                                }
                            },
                            data:this.sytz
                        }
                    ]
                };
                this.myChart5.setOption(pieoption);
            },
            //运输
            getEcharts6() {
                this.myChart6 = this.$echarts.init(
                    document.getElementById("echarts6")
                );
                var pieoption = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#fff'
                            }
                        }
                    },
                    toolbox: {
                        show:false
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: this.ysYear,
                            axisPointer: {
                                type: 'shadow'
                            },
                            axisLabel:{
                                color:'#9FDAFC',

                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            name: '投资金额（亿元）',

                            nameTextStyle:{
                                color:'#9FDAFC',
                            },
                            splitLine:{
                                show: true,
                                lineStyle:{
                                    color:['rgba(96, 118, 173, 0.3)'],
                                    type: 'solid'
                                }
                            },
                            axisLabel: {
                                textStyle:{//图例文字的样式
                                    color:'#9FDAFC',
                                    fontSize:14
                                },
                                formatter: '{value}'
                            }
                        }
                    ],
                    series: [
                        {
                            name: '投资金额',
                            type: 'bar',
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + '万元';
                                }
                            },
                            label: {
                                show: true,
                                color:'#9FDAFC',
                                fontSize:'12px',
                                position: 'top',
                            },
                            barWidth : 30,
                            itemStyle:{
                                normal:{
                                    barBorderRadius:[5,5,0,0],
                                    color: (params) => {
                                        return new this.$echarts.graphic.LinearGradient(0,0,0,1,[
                                            {offset: 0.2, color:'#FEE6B6'},
                                            {offset: 1, color: '#FDB628'}
                                        ])
                                    }
                                }
                            },
                            data: this.ystz
                        }
                    ]
                };
                this.myChart6.setOption(pieoption);
            },
        }
    }
</script>

<style scoped lang="less">
    .list{
        cursor: pointer;
        line-height: 88px;
        width: 339px;
        height: 88px;
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #FFFFFF;
        background: url("../../assets/images/statistics/bg-m.png");
        background-size: 339px 88px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .name{
            text-align: center;
            width: 250px;
        }
    }
    .list1{
        background: url("../../assets/images/statistics/bg-m-active.png");
        background-size: 339px 88px;
    }
    .PlanRight{
        /*display: flex;*/
        /*align-items: center;*/
        /*justify-content: space-between;*/
        flex-wrap: wrap;
        padding: 30px 38px;
        width: 100%;
        height: 86vh;
        background: url("../../assets/images/statistics/bg-ghgl-r.png") ;
        background-size: 100% 100%;
        .pieEcharts{
            width: 30%;
            height: 25vh;
        }
        .smallPieEcharts{
            width: 40%;
            height: 25vh;
        }
        .singlePieEcharts{
            width: 80%;
            height: 25vh;
        }
        .totalEchart{
            width: 100%;
            height: 30vh;
            margin-bottom: 10px;
            background: rgba(0,31,103,0.4500);
        }
        .echart{
            width: 49%;
            height: 26.8vh;
            background: rgba(0,31,103,0.4500);
            margin-bottom: 10px;
        }
        .label{
            margin-top: 20px;
            color: #fff;
            font-size: 20px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            line-height: 38px;
            width: 133px;
            height: 40px;
            background: url("../../assets/images/statistics/bg-tit.png");
            div{
                margin-left: 23px;
            }
        }
    }
    .echarts{
        width: auto;
        height: 25vh;
    }
    .totalWrap{
        margin-top: -50px;
        width: 200px;
        line-height: 24px;
    }
    .totalTie{
        margin-top: 15px;
        font-size: 20px;
        font-weight: 500;
        color: #9FDAFC;
    }
    .tieNor{
        color: #26B0FF;
        font-size: 12px;
    }
    .tieBig{
        margin-top: 18px;
        font-size: 20px;
        font-weight: 500;
        color: #00FFFF;
    }
    .el-table--border{
        color: #ffffff;
    }
    .el-table--border td,
    .el-table--border th {
        border-bottom: 1px solid #EBEEF5;
        height: 28px;
        text-align: center;
        padding: 5px 8px;
        line-height: 18px;
    }

    .el-table--border td,
    .el-table--border th {
        border-right: 1px solid  #EBEEF5;
    }

    .el-table--border thead th {
        background: #f5f7fa;
    }
</style>
