<template>
    <div class="honePage"></div>
</template>

<script>
    export default {
        name: "First"
    }
</script>

<style scoped>
.honePage{
    width: 100% ;
    height: 100vh;
    background: url("../../assets/images/hy.jpg");
    background-size:auto 100%;
    background-position: center center;
}
</style>
