  <template>
  <el-container class="con-auto">
    <el-main>
      <el-row :gutter="15" class="index-top">
        <el-col :span="12">
          <div class="box">
            <div class="box-content">
              <h1>2021年投资</h1>
              <div class="echarts" id="echarts1" style="height: 400px"></div>
            </div>
          </div>
        </el-col>
        <el-col :span="6" class="mb15">
          <div class="box">
            <div class="box-title">
              <div class="fenlei">
                <el-link href="#" :underline="false"><img src="../../assets/images/road.png" /></el-link>
              </div>
              <h1><el-link href="#" :underline="false">公路</el-link></h1>
              <b class="index-num">398.9</b> <small>亿元</small>
            </div>
            <div class="box-content">
              <div class="fenlei-info">
                <p>
                  高速公路十四五投资 <strong>{{ gsglsswtz }}</strong> 亿元，建设
                  <strong>{{ gsgljsgm }}</strong> 公里；普通国省道十四五投资
                  <strong>{{
                    (parseFloat(ptgdsswtz) + parseFloat(ptsdsswtz)).toFixed(1)
                  }}</strong>
                  亿元，建设
                  <strong>{{
                    (parseFloat(ptgdjsgm) + parseFloat(ptsdjsgm)).toFixed(1)
                  }}</strong>
                  公里。
                </p>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="6" class="mb15">
          <div class="box">
            <div class="box-title">
              <div class="fenlei">
                <el-link href="#" :underline="false"><img src="../../assets/images/railway.png" /></el-link>
              </div>
               <h1><el-link href="#" :underline="false">铁路</el-link></h1>
              <b class="index-num">158.4</b> <small>亿元</small>
            </div>
            <div class="box-content">
              <div class="fenlei-info">
                <p>
                  高速铁路投资 <strong>87.5</strong> 亿元，建设
                  <strong>676.7</strong> 公里；普速铁路投资
                  <strong>70.9</strong> 亿元，建设 <strong>791.8</strong> 公里。
                </p>
              </div>
            </div>
          </div>
        </el-col>

        <el-col :span="6">
          <div class="box">
            <div class="box-title">
              <div class="fenlei">
                <el-link href="#" :underline="false"><img src="../../assets/images/airport.png" /></el-link>
              </div>
              <h1><el-link href="#" :underline="false">机场</el-link></h1>
              <b class="index-num">28.2</b> <small>亿元</small>
            </div>
            <div class="box-content">
              <div class="fenlei-info">
                <p>
                  运输机场投资 <strong>26.1</strong> 亿元，建设
                  <strong>10</strong> 项； 通用机场投资
                  <strong>2.1</strong> 亿元，建设 <strong>3</strong> 项。
                </p>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="box">
            <div class="box-title">
              <div class="fenlei">
                <el-link href="#" :underline="false"><img src="../../assets/images/hinge.png" /></el-link>
              </div>
               <h1><el-link href="#" :underline="false">枢纽站场</el-link></h1>
             
              <b class="index-num">8.0</b> <small>亿元</small>
            </div>
            <div class="box-content">
              <div class="fenlei-info">
                <p>
                  综合客运枢纽投资 <strong>2</strong> 亿元，建设
                  <strong>3</strong> 项；多式联运型货运枢纽投资
                  <strong>4.6</strong> 亿元，建设
                  <strong>7</strong> 项；一般客运场站投资 <strong>1.4</strong>
                  亿元。
                </p>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="15" class="">
        <el-col :span="12">
          <div class="box">
            <h3 class="tit1">公路统计</h3>

            <div class="box-content">
              <el-table
                size="small "
                :data="glData"
                show-summary
                height="240"
                style="width: 100%"
              >
                <el-table-column prop="fl" label=""> </el-table-column>
                <el-table-column prop="xmsl" label="项目数量">
                </el-table-column>
                <el-table-column
                  prop="jsgm"
                  label="建设规模（公里）"
                  min-width="120px"
                >
                </el-table-column>

                <el-table-column
                  prop="sswtz"
                  label="2021年投资（亿元）"
                  min-width="150px"
                >
                </el-table-column>
              </el-table>
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="box">
            <h3 class="tit1">铁路统计</h3>

            <div class="box-content">
              <el-table
                size="small "
                :data="tlData"
                show-summary
                style="width: 100%"
                height="240"
              >
                <el-table-column prop="fl" label=""> </el-table-column>
                <el-table-column prop="xmsl" label="项目数量">
                </el-table-column>
                <el-table-column
                  prop="jsgm"
                  label="建设规模（公里）"
                  min-width="120px"
                >
                </el-table-column>

                <el-table-column
                  prop="sswtz"
                  label="2021年投资（亿元）"
                  min-width="150px"
                >
                </el-table-column>
              </el-table>
            </div>
          </div>
        </el-col>
        <el-col :span="12" class="mt15">
          <div class="box">
            <h3 class="tit1">机场统计</h3>

            <div class="box-content">
              <el-table
                size="small "
                :data="jcData"
                show-summary
                style="width: 100%"
                height="240"
              >
                <el-table-column prop="fl" label=""> </el-table-column>
                <el-table-column prop="xmsl" label="项目数量">
                </el-table-column>
                <el-table-column prop="sswtz" label="2021年投资（亿元）">
                </el-table-column>
              </el-table>
            </div>
          </div>
        </el-col>

        <el-col :span="12" class="mt15">
          <div class="box">
            <h3 class="tit1">枢纽站场统计</h3>

            <div class="box-content">
              <el-table
                size="small "
                :data="snzcData"
                show-summary
                style="width: 100%"
                height="240"
              >
                <el-table-column prop="fl" label=""> </el-table-column>
                <el-table-column prop="xmsl" label="项目数量">
                </el-table-column>
                <el-table-column prop="sswtz" label="2021年投资（亿元）">
                </el-table-column>
              </el-table>
            </div>
          </div>
        </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>
<script>
export default {
  methods: {
    //公路分类汇总
    roadTotal() {
      var that = this;
      this.http
        .post("/api/Plan_road/getPageTotalByGllx?xmnd=2021")
        .then((res) => {
          res.group.forEach((element) => {
            var jsgm = parseFloat(
              element.jsgmgs +
                element.jsgmyj +
                element.jsgmej +
                element.jsgmsj +
                element.jsgmqs / 1000
            ).toFixed(1);

            if (element.gllx == "高速公路") {
              this.gsgljsgm = jsgm - 15.4;
              this.gsglsswtz = element.sswtz + 1590.1;
            }

            if (element.gllx == "普通国道") {
              this.ptgdjsgm = jsgm;
              this.ptgdsswtz = element.sswtz;
            }

            if (element.gllx == "普通省道") {
              this.ptsdjsgm = jsgm;
              this.ptsdsswtz = element.sswtz;
              // console.log("ptsdjsgm", this.ptsdjsgm);
            }

            var item = {
              fl: element.gllx,
              xmsl: element.xmsl,
              jsgm: parseFloat(
                element.jsgmgs +
                  element.jsgmyj +
                  element.jsgmej +
                  element.jsgmsj +
                  element.jsgmqs / 1000
              ).toFixed(1),
              sswtz: parseFloat(element.sswtz).toFixed(1),
            };
            that.glData.push(item);
          });

          that.glData.push({
            fl: "路面提升改造",
            xmsl: "164",
            jsgm: "4506",
            sswtz: "111",
          });
          that.glData.push({
            fl: "农村公路",
            xmsl: "1",
            jsgm: "27000",
            sswtz: "370",
          });
          res.group331.forEach((element) => {
            var item = {
              fl: "其中：G331丹阿公路",
              xmsl: element.xmsl,
              jsgm: parseFloat(
                element.jsgmgs +
                  element.jsgmyj +
                  element.jsgmej +
                  element.jsgmsj +
                  element.jsgmqs / 1000
              ).toFixed(1),
              sswtz: parseFloat(element.sswtz).toFixed(1),
            };
            that.glData.push(item);
          });
        });
    },
  },
  mounted() {
    var pieChart = this.$echarts.init(document.getElementById("echarts1"));
    var pieoption = {
      title: {
        text: "594",
        subtext: "总投资(亿元)",
        left: "center",
        top: "45%",
        textStyle: { fontSize: 35, fontWeight: 600 },
      },

      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c} ({d}%)",
      },
      color: ["#F29900", "#5390FF", "#634CF0", "#F28965"],
      legend: {
        y: "bottom",
        data: ["公路", "铁路", "机场", "枢纽站场"],
      },
      calculable: true,

      series: [
        {
          name: "2021年度计划投资",
          type: "pie",
          itemStyle: {
            borderRadius: 4,
            borderColor: "#fff",
            borderWidth: 1,
          },
          radius: ["46%", "72%"],
          data: [
            { value: 398.9, name: "公路" },
            { value: 158.4, name: "铁路" },

            { value: 28.2, name: "机场" },
            { value: 8.0, name: "枢纽站场" },
          ],
        },
      ],
    };
    pieChart.setOption(pieoption);
    this.roadTotal();
  },
  data() {
    return {
      gsglsswtz: 0,
      gsgljsgm: 0,
      ptsdsswtz: 0,
      ptsdjsgm: 0,
      ptgdsswtz: 0,
      ptgdjsgm: 0,
      lmtsgzxmtz: 0,
      ncgltz: 0,
      glData: [{}],
      tlData: [
        {
          fl: "高铁",
          xmsl: "3",
          jsgm: "676.7",
          sswtz: "87.5",
        },
        {
          fl: "普通铁路",
          xmsl: "6",
          jsgm: "361.6",
          sswtz: "64.9",
        },
        {
          fl: "铁路道口平改立工程",
          xmsl: "1",
          jsgm: "383个",
          sswtz: "6",
        },
      ],
      jcData: [
        {
          fl: "枢纽机场",
          xmsl: "3",
          sswtz: "10.2",
        },
        {
          fl: "支线机场",
          xmsl: "6",
          sswtz: "12.4",
        },
        {
          fl: "通用机场",
          xmsl: "3",
          sswtz: "2.1",
        },
        {
          fl: "民用运输机场",
          xmsl: "1",
          sswtz: "3.5",
        },
      ],
      snzcData: [
        {
          fl: "货运枢纽",
          xmsl: " 7",
          sswtz: "4.7",
        },
        {
          fl: "客运枢纽",
          xmsl: "3",
          sswtz: "2",
        },
        {
          fl: "通用集散",
          xmsl: "4",
          sswtz: "1.4",
        },
      ],
    };
  },
};
</script>

    <style scoped>
</style>
